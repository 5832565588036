/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react'
import { Button } from '../../../node_modules/@mui/material/index';
import { Link } from '../../../node_modules/react-router-dom/dist/index';

const ListPolicy = () => {
    const [Policy, setPolicy] = useState([]);
    
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="w-100">
                            <Button variant="contained" component={Link} to="/policies/create" color="warning">Add New</Button>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="w-100 table-responsive">
                                <table className="table table-sm table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sr</th>
                                            <th>Policy</th>
                                            <th>Action</th>
                                            <th>Created At</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Policy.map((plc, index) => (
                                                <>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{plc['title']}</td>
                                                        <td>
                                                            <div className="d-flex">
                                                                <Button variant="contained" component={Link} to="/" color="warning">Edit</Button>
                                                                <Button variant="contained" component={Link} to="/" color="error">Delete</Button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ListPolicy
