/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import axios from '../../../node_modules/axios/index';
import { Alert, Button, Link } from '../../../node_modules/@mui/material/index';


function ChapterCreate() {
    const [standards, setStandards] = useState([]);
    const [chapter, setChapter] = useState(null);
    const [image, setImage] = useState(null);
    const [subjects, setSubjects] = useState([]);
    const [standard_id, setStandard_id] = useState([]);
    const [subject_id, setSubject_id] = useState([]);
    const [message, setMessage] = useState({ message: "", alert: "" });

    const subjectHandler = (event) => {

        setSubject_id(event.target.value);
    }
    const chapterHandler = (event) => {
        setChapter(event.target.value);
    }
    const handleStandard_id = async (e) => {
        let value = e.target.value;
        setSubject_id(null);
        setStandard_id(value);



        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/standards/get_subject_by_standard_id/' + value,
            headers: {}
        };

        await axios.request(config)
            .then((response) => {
                setSubjects(response.data)
                setSubject_id(null);
            })
            .catch((error) => {
                console.log(error);
            });




    }
    const getStandard = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/standards/',
            headers: {}
        };

        await axios.request(config)
            .then((response) => {
                setStandards(response.data);

            })
            .catch((error) => {
                console.log(error);
            });

    }
    const handleFileChange = (event) => {
        // Handle file changes here
        const selectedFile = event.target.files[0];
        setImage(selectedFile);

    };
    const save_chapter = async () => {

        const FormData = require('form-data');
        let data = new FormData();
        data.append('standard_id', standard_id);
        data.append('subject_id', subject_id);
        data.append('image', image);
        data.append('chapter', chapter);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/chapters',
            headers: {

            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                setMessage({ message: response.data.message, alert: response.data.success === 0 ? 'alert-danger' : 'alert-success' });
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            })
            .catch((error) => {
                console.log(error);
            });

    }

    useEffect(() => {
        getStandard();

    }, [])



    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="w-100 card card-body">
                                <div className="row gy-4">
                                    <div className="col-md-12 mb-3">
                                        {message.message ? (
                                            <>
                                                <Alert severity="success">{message.message}</Alert>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div className="col-md-12">
                                        <div className="text-end">
                                            <Button component={Link} href="/chapters" variant="contained" color="success">Back</Button>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='w-100'>
                                            <TextField fullWidth defaultValue="" select onChange={handleStandard_id} label="Select Standard"  >
                                                {standards.map((option, i) => (
                                                    <MenuItem key={`stan_d${option._id}${i} `} value={option._id}>
                                                        {option.standard}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='w-100'>
                                            <TextField select defaultValue="" onChange={subjectHandler} fullWidth label="Select Subject"  >
                                                <MenuItem key='0' value="">---Select--</MenuItem>
                                                {subjects.map((option) => (
                                                    <MenuItem key={`subjects${option._id}`} value={option._id}>
                                                        {option.subject}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="w-100">
                                            <TextField fullWidth label="Enter Chapter Title" onChange={chapterHandler} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="w-100">
                                            {/* <InputLabel htmlFor="file-input">Upload Chapter Image</InputLabel> */}
                                            <TextField
                                                id="file-input"
                                                type="file"
                                                fullWidth

                                                onChange={handleFileChange}
                                                inputProps={{ accept: 'image/*' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="col-md-4">
                                            <Button variant="contained" onClick={save_chapter} color="success" fullWidth>Submit</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ChapterCreate