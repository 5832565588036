/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import moment from '../../../node_modules/moment/moment';
import axios from '../../../node_modules/axios/index';


function UserList() {
    const [UserList, setUserList] = useState([])
    const getUsers = async () => {


        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/users/',
            headers: {}
        };

        await axios.request(config)
            .then((response) => {
                setUserList(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

    }
    useEffect(() => {
        getUsers();
    }, [])

    return (
        <>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table table-sm table-bordered table-hover ">
                                <thead>
                                    <tr>
                                        <th>Sr No</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Email</th>
                                        <th>Standard</th>
                                        <th>School</th>
                                        <th>Created At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        UserList.map((user, i) => (
                                            <>
                                                <tr key={user._id}>
                                                    <td>{i + 1}</td>
                                                    <td>{user.name}</td>
                                                    <td>{user.mobile}</td>
                                                    <td>{user.email}</td>
                                                    <td>
                                                        {user.standard_id?.standard}
                                                    </td>
                                                    <td>{user.school}</td>
                                                    <td>{moment(user.createdAt).format('DD-MMM-YYYY')}</td>
                                                </tr>
                                            </>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default UserList