// assets
import {
  LoginOutlined,
  ProfileOutlined,
  AppstoreAddOutlined,
  UserOutlined,
  ApartmentOutlined,
  BookOutlined,
  VideoCameraOutlined,
  FileTextOutlined
} from '@ant-design/icons';

// icons
const icons = {
  LoginOutlined,
  ProfileOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
  id: 'authentication',
  title: 'Master Data',
  type: 'group',
  children: [
    {
      id: 'standards1',
      title: 'Standards',
      type: 'item',
      url: '/standards',
      icon: icons.ProfileOutlined,
      target: false
    },
    {
      id: 'login1',
      title: 'Subjects',
      type: 'item',
      url: '/subject',
      icon: AppstoreAddOutlined,
      target: false
    },
    {
      id: 'chapters',
      title: 'Chapters',
      type: 'item',
      url: '/chapters',
      icon: ApartmentOutlined,
      target: false
    },
    {
      id: 'topics1',
      title: 'Topics',
      type: 'item',
      url: '/topics',
      icon: ApartmentOutlined,
      target: false
    },
    {
      id: 'notes1',
      title: 'Notes',
      type: 'item',
      url: '/notes',
      icon: BookOutlined,
      target: false
    },
    {
      id: 'videos1',
      title: 'Videos',
      type: 'item',
      url: '/videos',
      icon: VideoCameraOutlined,
      target: false
    },
    {
      id: 'users',
      title: 'Users',
      type: 'item',
      url: '/users',
      icon: UserOutlined,
      target: false
    },
    {
      id: 'questions',
      title: 'Question',
      type: 'item',
      url: '/questions',
      icon: FileTextOutlined,
      target: false
    },
    {
      id: 'policy',
      title: 'Policy',
      type: 'item',
      url: '/policies',
      icon: FileTextOutlined,
      target: false
    },
    {
      id: 'plans',
      title: 'plans',
      type: 'item',
      url: '/plans',
      icon: FileTextOutlined,
      target: false
    }
  ]
};

export default pages;
