/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import axios from '../../../node_modules/axios/index';

const CreatePlan = () => {
    const [subjects, setSubjects] = useState([]);
    const [std_id, setStd_id] = useState(null);
    const [standards, setStandards] = useState([]);
    const [fdata, setFdata] = useState([]);
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);
    const [plan, setPlan] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [planload, setPlanLoad] = useState(false);
    const get_standards = async () => {

        await axios.get(`${process.env.REACT_APP_BURL}standards`).then((resp => {
            setStandards(resp.data)
        }));
    }
    const standardhandler = (event) => {
        setPlanLoad(false)
        setLoaded(false)
        setErrors([])
        setSuccess(false)
        const _id = event.target.value;
        let arr = standards.find(obj => obj._id == _id);
        setStd_id(_id);
        setSubjects(arr.subjectIds);
        get_plan(_id);
        console.log(std_id)
    }
    const get_plan = async (stid) => {

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BURL}plans/show/${stid}`,
            headers: {}
        };

        await axios.request(config)
            .then((response) => {
                if (response.data.success == 1) {
                    setPlan(response.data.data);

                    if (setf_data()) {
                        setLoaded(true)
                    }

                }
            })
            .catch((error) => {
                console.log(error);
            });

    }
    const setf_data = () => {

        let arr = [];
        setPlanLoad(false);
        if (plan.hasOwnProperty('standard_id')) {
            console.log(plan.standard_id._id + ' and ' + std_id )
            if (plan.standard_id._id == std_id) {
                plan.plan.forEach(element => {
                    let obj = {
                        subject_id: element.subject_id._id,
                        pricing: element.pricing
                    }
                    if (arr.length < subjects.length) {
                        arr.push(obj)
                    }
                    setFdata(arr)

                });
            }else{
                setFdata([])
            }

        } else {

            setFdata([])
        }
        setPlanLoad(true);
        return true;
    }
    useEffect(() => {
        
        setf_data()
        

    }, [plan, std_id])
    useEffect(() => {
        get_standards();
    }, []);
    const pricehandler = (event) => {
        const subject_id = event.target.dataset.id;
        const value = event.target.value;
        const name = event.target.name;
        const fid = fdata.findIndex(obj => obj.subject_id === subject_id);
        let nobj;
        if (fid > -1) {
            let f_data = [...fdata];
            nobj = { ...f_data[fid] }
            nobj.pricing[name] = value;
            f_data[fid] = nobj;
            setFdata(f_data)
        } else {
            nobj = {
                subject_id: subject_id,
                pricing: {}
            };
            nobj.pricing[name] = value;
            let frr = [...fdata, nobj];
            setFdata(frr)
        }
        console.log(fdata);

    }

    const create_plan = async () => {

        let data = JSON.stringify({
            "standard_id": std_id,
            "plan": fdata
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BURL}plans`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                if (response.data.success == '1') {
                    setStd_id(null)
                    setFdata([]);
                    setSuccess(true);
                    setErrors([])
                } else {
                    setErrors(response.data.errors)
                    setSuccess(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });

    }
    const getInputValue = (id, type) => {

        let obj = fdata && fdata.find(obj => obj.subject_id == id);
        console.log(obj)
        return obj?.pricing[type] ?? null
    }





    return (
        <>
            <section className='p-5 shadow'>
                <div className="container">
                    <div className="row mb-4">
                        {
                            success === true && (
                                <>
                                    <div className="col-md-12 mb-4">
                                        <div className="alert alert-success">Create successfully</div>
                                    </div>
                                </>
                            )
                        }
                        {
                            errors.length > 0 && (
                                <>
                                    <div className="col-md-12 mb-4">
                                        <div className="alert alert-danger">
                                            <ul className="mb-0">
                                                {
                                                    errors.map((err, i) => (
                                                        <>
                                                            <li key={i + 1000}>
                                                                {err?.msg ?? err}

                                                            </li>
                                                        </>
                                                    ))
                                                }

                                            </ul>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        <div className="col-md-4">
                            <label htmlFor="standard">Select Standard</label>
                            <select name="" onChange={standardhandler} id="standard" className='form-select border border-dark'>
                                <option value="" selected disabled>---Select---</option>
                                {
                                    standards.map((st, j) => (
                                        <>
                                            <option key={j + 12} value={st._id}>{st.standard}</option>
                                        </>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="w-100 mb-2 table-responsive">
                                <table className="table table-sm border table-bordered border-dark">
                                    <thead>
                                        <th>Sr No.</th>
                                        <th>Subject</th>
                                        <th>Half Year</th>
                                        <th>Yearly</th>
                                    </thead>
                                    <tbody>
                                        {
                                            subjects.length > 0 && planload == true && loaded && (
                                                <>
                                                    {
                                                        subjects.map((subject, i) => (
                                                            <>
                                                                <tr>
                                                                    <td>{i + 1}</td>
                                                                    <td>{subject.subject}</td>
                                                                    <td>
                                                                        <input type="text" name="half_year" value={fdata.find(obj => obj.subject_id == subject._id)?.pricing?.half_year} onChange={pricehandler} id="" data-id={subject._id} className="form-control border shadow-none border-dark" />

                                                                    </td>
                                                                    <td>
                                                                        <input type="text" name="yearly" value={getInputValue(subject._id, 'yearly')} onChange={pricehandler} id="" data-id={subject._id} className="form-control border shadow-none border-dark" />

                                                                    </td>
                                                                </tr>
                                                            </>
                                                        ))
                                                    }
                                                </>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="w-100">
                                <button onClick={create_plan} className="btn btn-primary shadow px-md-4">Update Plan</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CreatePlan
