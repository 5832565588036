/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'

import axios from '../../../node_modules/axios/index';
import { Alert, Button, Link } from '@mui/material/index';
import { MenuItem, TextField } from '../../../node_modules/@mui/material/index';
import Image from '../../../node_modules/react-bootstrap/esm/Image';

function TopicsList() {
    const [standards, setStandards] = useState([]);
    const [chapter, setChapter] = useState([]);
    const [chapter_id, setChapter_id] = useState({});
    const [topic, setTopic] = useState([]);

    const [subjects, setSubjects] = useState([]);
    const [standard_id, setStandard_id] = useState("");
    const [subject_id, setSubject_id] = useState("");
    const [message, setMessage] = useState({ message: "", alert: "" });

    const subjectHandler = (event) => {

        setSubject_id(event.target.value);
    }
    const chapterHandler = (event) => {
        setChapter_id(event.target.value);
    }
    const handleStandard_id = async (e) => {
        let value = e.target.value;
        setSubject_id("");
        setStandard_id(value);



        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/standards/get_subject_by_standard_id/' + value,
            headers: {}
        };

        await axios.request(config)
            .then((response) => {
                setSubjects(response.data)
                setSubject_id("");

            })
            .catch((error) => {
                console.log(error);
            });




    }
    const getStandard = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/standards/',
            headers: {}
        };

        await axios.request(config)
            .then((response) => {
                setStandards(response.data);
                setTopic([])
            })
            .catch((error) => {
                console.log(error);
                setMessage({ message: "", alert: "" });
            });

    }

    const get_Topic = async () => {
        if (chapter_id != "" && chapter_id != undefined && chapter_id != null) {

            let data = JSON.stringify({
                "standard_id": standard_id,
                "subject_id": subject_id,
                "chapter_id": chapter_id
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'http://194.163.34.149:8185/topics/getAll',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    setTopic(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }
    const getChapter = () => {
        let sid = subject_id;
        let st_id = standard_id;
        let data = JSON.stringify({
            "standard_id": st_id,
            "subject_id": sid
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/chapters/getAll',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios.request(config)
            .then((response) => {
                if (response.status == 200) {
                    setChapter(response.data);
                    setTopic([])
                }

            })
            .catch((error) => {
                console.log(error);
            });

    }
    const handleDelete = (e) => {
        let id = e.target.dataset.id;
        if (confirm('are you sure ?')) {
            alert(id);
        }
    }

    useEffect(() => {
        getStandard();
    }, [])
    useEffect(() => {
        getChapter();
    }, [subject_id])

    useEffect(() => {
        get_Topic();
    }, [chapter_id])



    return (
        <>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            {message.message ? (
                                <>
                                    <Alert severity="success">{message.message}</Alert>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className='w-100'>
                                        <TextField fullWidth defaultValue={standard_id} select value={standard_id} onChange={handleStandard_id} label="Select Standard"  >
                                            {standards.map((option, i) => (
                                                <MenuItem key={`stan_d${option._id}${i} `} value={option._id}>
                                                    {option.standard}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='w-100'>
                                        <TextField select defaultValue={subject_id} value={subject_id} onChange={subjectHandler} fullWidth label="Select Subject"  >
                                            <MenuItem key='0' value="">---Select--</MenuItem>
                                            {subjects.map((option) => (
                                                <MenuItem key={`subjects${option._id}`} value={option._id}>
                                                    {option.subject}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='w-100'>
                                        <TextField select defaultValue={chapter_id} value={chapter_id} onChange={chapterHandler} fullWidth label="Select Chapter"  >
                                            <MenuItem key='0' value="">---Select--</MenuItem>
                                            {chapter.map((option) => (
                                                <MenuItem key={`subjects${option._id}`} value={option._id}>
                                                    {option.chapter}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="text-end">
                                <Button component={Link} href="/topics/create" variant="contained" color={'primary'}>Add New Topic</Button>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <table className="table table-sm table-bordered table-hover ">
                                <thead>
                                    <tr>
                                        <th>Sr No</th>
                                        <th>Topic</th>
                                        <th>Image</th>
                                        <th>Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        topic.map((item, i) => (
                                            <>
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{item.topic}</td>
                                                    <td>
                                                        <Image src={`http://194.163.34.149:8185/uploads/${item.image.name}`} className="img-fluid" width={100} />
                                                    </td>
                                                    <td>
                                                        <Button color="warning" variant="contained">Edit</Button>
                                                        <Button color="error" className="ms-2" onClick={handleDelete} data-id={item._id} variant="contained">Delete</Button>
                                                    </td>
                                                </tr>
                                            </>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TopicsList