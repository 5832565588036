/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'

import axios from '../../../node_modules/axios/index';
import { Alert, Button, Link } from '@mui/material/index';
import { MenuItem, TextField } from '@mui/material/index';



function VideosCreate() {
    const [standards, setStandards] = useState([]);
    const [description, setDescription] = useState("");
    const [chapter, setChapter] = useState([]);
    const [chapter_id, setChapter_id] = useState({});
    const [topic_id, setTopic_id] = useState("");
    const [topic, setTopic] = useState([]);

    const [subjects, setSubjects] = useState([]);
    const [standard_id, setStandard_id] = useState("");
    const [subject_id, setSubject_id] = useState("");
    const [message, setMessage] = useState({ message: "", alert: "" });
    const topicHandler = (event) => {
        setTopic_id(event.target.value);
    }

    const subjectHandler = (event) => {

        setSubject_id(event.target.value);
    }
    const chapterHandler = (event) => {
        setChapter_id(event.target.value);
    }
    const handleStandard_id = async (e) => {
        let value = e.target.value;
        setSubject_id("");
        setStandard_id(value);



        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/standards/get_subject_by_standard_id/' + value,
            headers: {}
        };

        await axios.request(config)
            .then((response) => {
                setSubjects(response.data)
                setSubject_id("");

            })
            .catch((error) => {
                console.log(error);
            });




    }
    const getStandard = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/standards/',
            headers: {}
        };

        await axios.request(config)
            .then((response) => {
                setStandards(response.data);
                setTopic([])
            })
            .catch((error) => {
                console.log(error);
                setMessage({ message: "", alert: "" });
            });

    }

    const get_Topic = async () => {
        if (chapter_id != "" && chapter_id != undefined && chapter_id != null) {

            let data = JSON.stringify({
                "standard_id": standard_id,
                "subject_id": subject_id,
                "chapter_id": chapter_id
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'http://194.163.34.149:8185/topics/getAll',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    setTopic(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }
    const getChapter = () => {
        let sid = subject_id;
        let st_id = standard_id;
        let data = JSON.stringify({
            "standard_id": st_id,
            "subject_id": sid
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/chapters/getAll',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios.request(config)
            .then((response) => {
                if (response.status == 200) {
                    setChapter(response.data);
                    setTopic([])
                }

            })
            .catch((error) => {
                console.log(error);
            });

    }
    const save_notes = () => {
        if (topic_id != "" && topic_id != undefined) {


            let data = JSON.stringify({
                "standard_id": standard_id,
                "subject_id": subject_id,
                "chapter_id": chapter_id,
                "topic_id": topic_id,
                "video": description
            });
            console.log(data);


            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'http://194.163.34.149:8185/videos',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    setMessage({ message: response.data.message, alert: response.data.success === 0 ? 'alert-danger' : 'alert-success' });
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 500);
                    setStandard_id("")
                    setSubject_id("")
                    setChapter_id("")
                    description("")
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setMessage({ message: "Topic id is null", alert: 'alert-success' })
        }
    }

    useEffect(() => {
        getStandard();
    }, [])
    useEffect(() => {
        getChapter();
    }, [subject_id])

    useEffect(() => {
        get_Topic();
    }, [chapter_id])



    return (
        <>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            {message.message ? (
                                <>
                                    <Alert severity="success">{message.message}</Alert>
                                </>
                            ) : ('')}
                        </div>
                        <div className="col-md-12">
                            <div className="w-100 card card-body ">
                                <div className="row mb-4 gy-3">
                                    <div className="col-md-12 mb-3">
                                        <div className="text-end">
                                            <Button component={Link} href="/videos/" variant="contained" color={'success'}>View All Videos</Button>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className='w-100'>
                                            <TextField fullWidth defaultValue={standard_id} select value={standard_id} onChange={handleStandard_id} label="Select Standard"  >
                                                {standards.map((option, i) => (
                                                    <MenuItem key={`stan_d${option._id}${i} `} value={option._id}>
                                                        {option.standard}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className='w-100'>
                                            <TextField select defaultValue={subject_id} value={subject_id} onChange={subjectHandler} fullWidth label="Select Subject"  >
                                                <MenuItem key='0' value="">---Select--</MenuItem>
                                                {subjects.map((option) => (
                                                    <MenuItem key={`subjects${option._id}`} value={option._id}>
                                                        {option.subject}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className='w-100'>
                                            <TextField select defaultValue={chapter_id} value={chapter_id} onChange={chapterHandler} fullWidth label="Select Chapter"  >
                                                <MenuItem key='0' value="">---Select--</MenuItem>
                                                {chapter.map((option) => (
                                                    <MenuItem key={`subjects${option._id}`} value={option._id}>
                                                        {option.chapter}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className='w-100'>
                                            <TextField select defaultValue={topic_id} value={topic_id} onChange={topicHandler} fullWidth label="Select Chapter"  >
                                                <MenuItem key='0' value="">---Select--</MenuItem>
                                                {topic.map((option) => (
                                                    <MenuItem key={`subjects${option._id}`} value={option._id}>
                                                        {option.topic}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="w-100">
                                            <TextField fullWidth id="youtube" value={description} onChange={(event) => setDescription(event.target.value)} label="Enter youtube link" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row gy-4">

                                    <div className="col-md-12">
                                        <Button variant="contained" onClick={save_notes} color="success">Submit</Button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default VideosCreate