/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'


import { Alert, Button } from '@mui/material/index';
import { MenuItem, TextField } from '@mui/material/index';
import Form from 'react-bootstrap/Form';
import { Link } from '../../../node_modules/@mui/material/index';
import axios from '../../../node_modules/axios/index';







function QuestionCreate() {
    const [standards, setStandards] = useState([]);
    const [questionImg, seQuestionImg] = useState(null);
    const [description, setDescription] = useState("");
    const [options, setOptions] = useState([]);
    const [chapter, setChapter] = useState([]);
    const [chapter_id, setChapter_id] = useState("");
    const [topic_id, setTopic_id] = useState("");
    const [topic, setTopic] = useState([]);
    const [optCount, setOptCount] = useState(2);
    const [correct, setCorrect] = useState(null);
    const [subjects, setSubjects] = useState([]);
    const [standard_id, setStandard_id] = useState("");
    const [subject_id, setSubject_id] = useState("");
    const [message, setMessage] = useState({ message: "", alert: "" });
    const [fileList, setFileList] = useState([]);
    const [format, setFormat] = useState('text');
    const [def_level, setDefLevel] = useState(null);

    const questionImageHandler = (e) => {
        const file = e.target.files[0];
        seQuestionImg(file)
    }
    const correctHandler = (e) => {

        setCorrect(e.target.value)
    }
    const topicHandler = (event) => {
        setTopic_id(event.target.value);
    }
    const optCountHandler = () => {
        setOptCount(optCount + 1);
    }
    const optionHandler = (e) => {
        const id = parseInt(e.target.dataset.id) + 1;
        const type = e.target.type;
        let isExists = options.findIndex(itm => itm.id === id);
        let arr = [...options];
        if (isExists > -1) {
            let obj = arr[isExists];
            if (type == "text") {
                obj['option_text'] = e.target.value;
            } else {
                obj['option_image'] = e.target.files[0];
            }
            arr[isExists] = obj;

        } else {
            let obj = {
                id: id,
                "option_text": "",
                'option_image': ''
            }
            if (type == "text") {
                obj['option_text'] = e.target.value;
            } else {
                obj['option_image'] = e.target.files[0];
            }

            arr = [...options, obj];
        }
        console.log(arr);
        setOptions(arr);

    }
    const optionImageHandler = (e) => {
        const id = parseInt(e.target.dataset.id) + 1;
        const file = e.target.files[0];
        let arr = [...fileList];
        let al = arr.findIndex(fl => fl.id == id);

        if (al > -1) {
            arr[al].file = file;
        } else {
            arr.push({ "id": id, "file": file });
        }
        setFileList(arr);
    }
    useEffect(() => {
        console.log(options)
    }, [options])

    const subjectHandler = (event) => {

        setSubject_id(event.target.value);
    }
    const chapterHandler = (event) => {
        setChapter_id(event.target.value);
    }
    const handleStandard_id = async (e) => {
        let value = e.target.value;
        setSubject_id("");
        setStandard_id(value);



        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/standards/get_subject_by_standard_id/' + value,
            headers: {}
        };

        await axios.request(config)
            .then((response) => {
                setSubjects(response.data)
                setSubject_id("");

            })
            .catch((error) => {
                console.log(error);
            });




    }
    const getStandard = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/standards/',
            headers: {}
        };

        await axios.request(config)
            .then((response) => {
                setStandards(response.data);
                setTopic([])
            })
            .catch((error) => {
                console.log(error);
                setMessage({ message: "", alert: "" });
            });

    }

    const get_Topic = async () => {
        if (chapter_id != "" && chapter_id != undefined && chapter_id != null && subject_id != undefined && subject_id != "") {

            let data = JSON.stringify({
                "standard_id": standard_id,
                "subject_id": subject_id,
                "chapter_id": chapter_id
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'http://194.163.34.149:8185/topics/getAll',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    setTopic(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }
    const getChapter = () => {
        if (subject_id != undefined && subject_id != "") {


            let sid = subject_id;
            let st_id = standard_id;
            let data = JSON.stringify({
                "standard_id": st_id,
                "subject_id": sid
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'http://194.163.34.149:8185/chapters/getAll',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };


            axios.request(config)
                .then((response) => {
                    if (response.status == 200) {
                        setChapter(response.data);
                        setTopic([])
                    }

                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }
    const save_notes = () => {

        let formData = new FormData();

        formData.append('standard_id', standard_id);
        formData.append('subject_id', subject_id);
        formData.append('chapter_id', chapter_id);
        formData.append('topic_id', topic_id);
        formData.append('question', description);
        formData.append('questionImage', questionImg);
        formData.append('def_level', def_level);
        if(options.length > 0){
            options.forEach((option, j) => {
                formData.append(`options[${j}][option_text]`, option.option_text);
                if(correct - 1  === j){
                    formData.append(`options[${j}][is_correct]`, true);
                }
            });
        }
        if(fileList.length > 0){
            fileList.forEach((fimg, i) => {
                formData.append(`options[${i}][option_text]`, "");
                formData.append(`image`, fimg.file);
                if(correct - 1  === i){
                    formData.append(`options[${i}][is_correct]`, true);
                }
            });
        }
       
       

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/questions',
            headers: {},
            data: formData
        };

        axios.request(config)
            .then((response) => {
                setMessage({ message: response.data.message, alert: response.data.success === 0 ? 'alert-danger' : 'alert-success' });

                setStandard_id("")
                setSubject_id("")
                setChapter_id("")
                setDescription("")
                setOptions([])
                setOptCount(2);

            })
            .catch((error) => {
                console.log(error);
            });

    }

    useEffect(() => {
        getStandard();
    }, [])
    useEffect(() => {
        getChapter();
    }, [subject_id])

    useEffect(() => {
        get_Topic();
    }, [chapter_id])



    return (
        <>
            <section className='py-4'>
                <div className="container-fluid">
                    <div className="row">

                        {message.message ? (
                            <>
                                <div className="col-md-12 mb-2">
                                    <Alert severity="success">{message.message}</Alert>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                        <div className="col-md-12 mb-3">
                            <div className="text-end">
                            <Button component={Link} href="/questions/" boxShadow={8} variant="contained" color={'success'}>View All Notes</Button>

                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="w-100 card card-body ">


                                <div className="row mb-4 gy-3">
                                    <div className="col-md-12">
                                        <div className="w-100 p-1 bg-success-subtle">Select All</div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className='w-100'>
                                            <TextField fullWidth defaultValue={standard_id} select value={standard_id} onChange={handleStandard_id} label="Select Standard"  >
                                                {standards.map((option, i) => (
                                                    <MenuItem key={`stan_d${option._id}${i} `} value={option._id}>
                                                        {option.standard}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className='w-100'>
                                            <TextField select defaultValue={subject_id} value={subject_id} onChange={subjectHandler} fullWidth label="Select Subject"  >
                                                <MenuItem key='0' value="">---Select--</MenuItem>
                                                {subjects.map((option) => (
                                                    <MenuItem key={`subjects${option._id}`} value={option._id}>
                                                        {option.subject}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className='w-100'>
                                            <TextField select defaultValue={chapter_id} value={chapter_id} onChange={chapterHandler} fullWidth label="Select Chapter"  >
                                                <MenuItem key='0' value="">---Select--</MenuItem>
                                                {chapter.map((option) => (
                                                    <MenuItem key={`subjects${option._id}`} value={option._id}>
                                                        {option.chapter}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className='w-100'>
                                            <TextField select defaultValue={topic_id} value={topic_id} onChange={topicHandler} fullWidth label="Select Topic"  >
                                                <MenuItem key='0' value="">---Select--</MenuItem>
                                                {topic.map((option) => (
                                                    <MenuItem key={`subjects${option._id}`} value={option._id}>
                                                        {option.topic}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </div>
                                </div>
                                <div className="row gy-4">
                                    <div className="col-md-12">
                                        <label htmlFor="questionn">
                                            Enter Question
                                        </label>
                                    </div>
                                    <div className="col-md-4">
                                        <input type="text" value={description} onChange={(event) => setDescription(event.target.value)} className="form-control" />
                                    </div>
                                    <div className="col-md-4">
                                        <input type="file" name="" onChange={questionImageHandler} id="" className="form-control" />
                                    </div>
                                    <div className="col-md-2">
                                    <TextField select defaultValue="" value={def_level} onChange={(event) => setDefLevel(event.target.value)} fullWidth label="Defficult Level"  >
                                                <MenuItem key='0' value="">---Select--</MenuItem>
                                                {[1,2,3].map((d) => (
                                                    <MenuItem key={`subjectsadfasd${d}`} value={d}>
                                                        {d}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                       
                                    </div>
                                    <div className="col-md-12"></div>
                                    <div className="col-md-12">
                                        <div className="w-100 p-1 bg-success-subtle mb-2">Enter Option</div>
                                        <div className="d-flex align-items-center gap-3" >
                                            <label htmlFor="inText" >
                                                <input type="radio" checked={format === 'text'} onClick={() => setFormat('text')} name="format" id="inText" />
                                                <span>In Text</span>
                                            </label>
                                            <label htmlFor="inImage">
                                                <input type="radio" checked={format === 'image'} onClick={() => setFormat('image')} name="format" id="inImage" />
                                                <span>In Image</span>
                                            </label>
                                        </div>
                                    </div>


                                    {
                                        [...Array(optCount)].map((itm, i) => (

                                            <>
                                                <div key={i + itm} className="col-md-8">
                                                    <div className="row">
                                                        {
                                                            format === 'text' ? (<>
                                                                <div className="col-6">
                                                                    <input type="text" value={options.find(opt => opt.id == i + 1)?.option_text} data-id={i} onChange={optionHandler} className="form-control" />
                                                                </div>
                                                            </>) : (<>
                                                                <div className="col-6">
                                                                    <input type="file"  data-id={i} onChange={optionImageHandler} className="form-control" />
                                                                </div>
                                                            </>)
                                                        }


                                                    </div>

                                                </div>
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <Form>
                                                            <Form.Check // prettier-ignore
                                                                type="radio"
                                                                id={`custom-switch${i + 1}`}
                                                                label="Is Correct"
                                                                value={i + 1}
                                                                onChange={correctHandler}
                                                                checked={correct - 1 == i ? 'checked' : ""}
                                                            />

                                                        </Form>



                                                    </div>
                                                </div>
                                            </>
                                        ))
                                    }


                                    <div className="col-md-2">
                                        <Button color="primary" className="d-block" onClick={optCountHandler} variant="outlined">Add More </Button>
                                    </div>

                                    <div className="col-md-12">
                                        <Button variant="contained" onClick={save_notes} color="success">Submit</Button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default QuestionCreate