/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Button, Link } from '../../../node_modules/@mui/material/index';
import axios from '../../../node_modules/axios/index';
import Image from '../../../node_modules/react-bootstrap/esm/Image';
import moment from '../../../node_modules/moment/moment';
import { Modal } from '../../../node_modules/react-bootstrap/esm/index';


function StandardList() {
    const [data, setData] = useState([]);
    const [d_id, setDid] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState({ message: "", alert: "" });
    const deleteStandard = (e) => {
        let id = e.target.dataset.id;
        setDid(id);
        handleOpen();
    }
    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    const delete_standard = async () => {
        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/standards/delete/' + d_id,
            headers: {}
        };

        await axios.request(config)
            .then((response) => {
                setMessage({ message: response.data.message, alert: response.data.success === 1 ? 'alert-success' : 'alert-danger' })
                getAll();
                handleClose();
                setDid(null);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const getAll = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/standards/',
            headers: {}
        };

        axios
            .request(config)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        getAll();
    }, []);
    return (
        <section className="py-0">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <div className="w-100 text-end">
                            <Button color="success" variant="contained" component={Link} href="/standards/create">
                                Add New Standard
                            </Button>
                        </div>
                    </div>
                    <div className="col-md-12">
                        {
                            message.message ? (<>
                                <div className={`alert ${message.alert}`}>
                                    {message.message}
                                </div>
                            </>) : ('')
                        }
                    </div>
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-sm table-bordered">
                                <thead className="bg-warning">
                                    <tr>
                                        <th>Sr No</th>
                                        <th>Standard</th>
                                        <th>Subject</th>
                                        <th>Image</th>
                                        <th>Created At</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, i) => (
                                        <>
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{item.standard}</td>
                                                <td>
                                                    <ul>
                                                        {item.subjectIds.map((itm) => (
                                                            <>
                                                                <li>{itm.subject}</li>
                                                            </>
                                                        ))}
                                                    </ul>
                                                </td>
                                                <td>
                                                    <Image width={50} src={`http://194.163.34.149:8185/uploads/${item.image.name}`} />
                                                </td>
                                                <td>{moment(item.createdAt).format('D-M-Y H:m A')}</td>
                                                <td>
                                                    <div className="d-flex gap-1">
                                                        <Button color="warning" variant="contained" component={Link} href="/subjects/edit/">
                                                            Edit
                                                        </Button>
                                                        <Button color="error" onClick={deleteStandard} data-id={item._id} variant="contained" component={Button} >
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <Modal centered show={open} onHide={handleClose}>

                <Modal.Body>
                    Are you sure to delete this ?
                </Modal.Body>
                <Modal.Footer>
                    <Button color="success" variant="contained" onClick={handleClose}>
                        No
                    </Button>
                    <Button color="error" className="ms-3" variant="contained" onClick={delete_standard}>
                        Yes, Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>

    );
}

export default StandardList;
