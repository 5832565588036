/* eslint-disable prettier/prettier */
import React, { useState } from 'react'
import { Alert, Button, Link, TextField } from '../../../node_modules/@mui/material/index'
import axios from '../../../node_modules/axios/index';
import { useNavigate } from "react-router-dom";

function SubjectsCreate() {
    
    const [subject, setSubject] = useState(null);
    const [message, setMessage] = useState({ message: "", alert: "" });
    const [selectedImage, setSelectedImage] = useState(null);
    const navigate = useNavigate();
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
    };
    const saveSubject = async () => {

        const FormData = require('form-data');

        let data = new FormData();
        data.append('image', selectedImage);
        data.append('subject', subject);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/subjects/',
            headers: {

            },
            data: data
        };

        await axios.request(config)
            .then((response) => {

                setMessage({ message: response.data.message, alert: response.data.success === 0 ? 'alert-danger' : 'alert-success' });
                setSelectedImage(null)
                setSubject(null)
                navigate('/subject');

            })
            .catch((error) => {
                console.log(error);
            });

    }

    return (
        <>
            <section className="">
                <div className="container">

                    <div className="row">
                        <div className="col-md-12">
                            <div className="w-100 text-end">
                                <Button color="success" variant='contained' component={Link} href="/subject">
                                    Back
                                </Button>
                            </div>
                        </div>

                    </div>
                    <div className="row gy-4">
                        <div className="col-md-12 mb-3">
                            {message.message ? (
                                <>
                                    <Alert severity="success">{message.message}</Alert>
                                </>
                            ) : (
                                ''
                            )}
                        </div>

                        <div className="col-md-6">
                            <TextField id="standard" value={subject} onChange={(event) => setSubject(event.target.value)} fullWidth label="Enter Subject" variant="outlined" />
                        </div>
                        <div className="col-md-6">
                            <TextField type="file" onChange={handleImageChange} id="file" fullWidth variant="outlined" />
                        </div>

                        <div className="col-md-4">
                            <Button onClick={saveSubject} fullWidth variant='contained' component={Button}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default SubjectsCreate