/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button, Link } from '@mui/material'
import axios from '../../../node_modules/axios/index';

function ChaptersList() {
    const [standards, setStandards] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [subject_id, setSubject_id] = useState(null);
    const [standard_id, setStandard_id] = useState(null);
    const [chapter, setChapter] = useState([]);
    const subjectHandler = (event) => {
        setSubject_id(event.target.value);

    }
    const handleStandard_id = async (e) => {
        let value = e.target.value;
        setStandard_id(value);
        setSubjects([]);
        setSubject_id(null);
        setChapter([]);
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/standards/get_subject_by_standard_id/' + value,
            headers: {}
        };
        await axios.request(config)
            .then((response) => {
                setSubjects(response.data)
                setSubject_id("");
            })
            .catch((error) => {
                console.log(error);
            });

    }
    const getStandard = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/standards/',
            headers: {}
        };
        await axios.request(config)
            .then((response) => {
                setStandards(response.data);

            })
            .catch((error) => {
                console.log(error);
            });

    }
    const getChapter = () => {
        let sid = subject_id;
        let st_id = standard_id;
        let data = JSON.stringify({
            "standard_id": st_id,
            "subject_id": sid
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/chapters/getAll',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios.request(config)
            .then((response) => {
                if (response.status == 200) {
                   
                    setChapter(response.data);
                }

            })
            .catch((error) => {
                console.log(error);
            });

    }
    useEffect(() => {
        getStandard();
    }, [])
    useEffect(() => {
        getChapter();
    }, [subject_id])



    return (
        <>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className='w-100'>
                                        <TextField fullWidth defaultValue="" select onChange={handleStandard_id} label="Select Standard"  >
                                            {standards.map((option, i) => (
                                                <MenuItem key={`stan_d${option._id}${i} `} value={option._id}>
                                                    {option.standard}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='w-100'>
                                        <TextField select defaultValue="" onChange={subjectHandler} fullWidth label="Select Subject"  >
                                            <MenuItem key='0' value="">---Select--</MenuItem>
                                            {subjects.map((option) => (
                                                <MenuItem key={`subjects${option._id}`} value={option._id}>
                                                    {option.subject}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="text-end">
                                <Button component={Link} href="/chapters/create" variant="contained" color={'success'}>Add New Chapter</Button>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <table className="table table-sm table-bordered table-hover ">
                                <thead>
                                    <tr>
                                        <th>Sr No</th>

                                        <th>Chapter</th>
                                        <th>Image</th>
                                        <th>Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        chapter.map((item, i) => (
                                            <>
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{item.chapter}</td>
                                                    <td>
                                                        <img width={100} className="rounded-3 shadow img-fluid" src={`http://194.163.34.149:8185/uploads/${item.image.name}`} alt="k" />
                                                    </td>
                                                    <td>
                                                        <div className="d-flex gap-1">
                                                            <Button color="warning" variant="contained">Edit</Button>
                                                            <Button color="error" variant="contained">Delete</Button>
                                                        </div>
                                                    </td>

                                                </tr>
                                            </>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ChaptersList