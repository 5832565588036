/* eslint-disable prettier/prettier */
import React from 'react'
import { Link } from '../../../node_modules/react-router-dom/dist/index'

const ListPlan = () => {
  return (
        <>
            <section className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="text-end w-100">
                            <Link to={"/plans/create"} className='btn btn-primary rounded-1 shadow'>Add New</Link>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <table className="table table-sm table-bordered">
                                <thead>
                                    <tr>
                                        <th>Sr No</th>
                                        <th>Subject</th>
                                        <th>Half Year</th>
                                        <th>Yearly</th>
                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}

export default ListPlan
