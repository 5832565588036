/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import axios from '../../../node_modules/axios/index';

import {
    CheckCircleOutlined
  } from '@ant-design/icons';
import { Button } from '../../../node_modules/@mui/material/index';
import { Link } from '../../../node_modules/react-router-dom/dist/index';
  

function QuestionList() {
const [questions, setQuestions] = useState([]);
const burl = process.env.REACT_APP_BURL;
    const get_questions = async () => {
        
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${burl}questions`,
            headers: {},
          
        };

        await axios.request(config)
            .then((response) => {
                if(response.data.success == 1){
                    setQuestions(response.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });

    }
    useEffect(() => {
        get_questions();
    }, [])
    

  return (
    <>
    <section>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 mb-2">
                    <div className="text-end">
                        <Link to={'/questions/create'} className='btn btn-sm btn-success rounded-0'>Add New Question</Link>
                    </div>
                </div>
                <div className="col-md-12">
                    <table className="table table-sm table-bordered">   
                        <thead>
                            <tr>
                                <th>Sr No</th>
                                <th>Question</th>
                                <th>Options</th>
                                <th>Def. Level</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                questions.map((item, i) => (
                                    <>
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td  style={{width:"50%"}}>
                                                {
                                                    item.question && item.question.text ? (<>
                                                        <p className='mb-0'>{item.question.text}</p>
                                                    </>) : ("")
                                                }
                                                {
                                                    item.question && item.question.question_image.name ? (<>
                                                        <img width={100} src={`${burl}uploads/${item.question.question_image.name}`} alt="" className="img-fluid" />
                                                    </>) : ("")
                                                }
                                            </td>
                                            <td>
                                                <ul className="">
                                                    {
                                                        item.options.map((opt, j) => (
                                                            <li key={i + j}>
                                                                {opt.option_text ? opt.option_text : ""}
                                                                {
                                                                    opt.image.name ? (<>
                                                                    <img src={`${burl}uploads/${opt.image.name}`} width={50} alt="" className="img-fluid" />
                                                                    </>) : ("")
                                                                }
                                                                {
                                                                    opt.is_correct ? (
                                                                        <>
                                                                       <span className="text-success"> <CheckCircleOutlined /> </span>
                                                                        </>
                                                                    ) : ("")
                                                                }
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </td>
                                            <td>
                                                {item.def_level}
                                            </td>
                                            <td>
                                                <div className="d-flex gap-1">
                                                    <Button variant="contained" color="warning">Edit</Button>
                                                    <Button variant="contained" color="error">Delete</Button>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default QuestionList