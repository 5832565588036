/* eslint-disable prettier/prettier */
// import MainCard from 'components/MainCard';
import React, { useState, useEffect } from 'react';
import { Box, Button, Link, TextField } from '../../../node_modules/@mui/material/index';
import Select from 'react-select'
import axios from '../../../node_modules/axios/index';
import { useNavigate } from "react-router-dom";

function StandardCreate() {
    
    const [subject, setSubject] = useState(null);
    const [subjectIds, setSubjectIds] = useState([]);
    const [options, setOptions] = useState([]);
    const [message, setMessage] = useState({ message: "", alert: "" });
    const [selectedImage, setSelectedImage] = useState(null);
    const navigate = useNavigate();
    const standardHandler = (e) => {
        setSubject(e.target.value);
    }
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
    };

    const handleSubjectIdChange = (selectedValues) => {
        setSubjectIds(selectedValues);
    };
    const subjectids = async () => {


        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/subjects/',
            headers: {}
        };

        await axios.request(config)
            .then((response) => {
                let arr = [...options];
                response.data.forEach((itm) => {
                    let obj = {
                        label: itm.subject,
                        value: itm._id
                    }
                    arr = [...arr, obj];
                });
                setOptions(arr);

            })
            .catch((error) => {
                console.log(error);
            });

    }
    const saveStandard = async () => {
        let ar = [];
        subjectIds.forEach((itm) => {
            ar.push(itm.value);
        })


        const FormData = require('form-data');

        let data = new FormData();
        data.append('standard', subject);
        data.append('image', selectedImage);

        ar.forEach((itm, j) => {
            data.append(`subjectIds[${j}]`, itm);
        })

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/standards/',
            headers: {

            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setMessage({ message: response.data.message, alert: response.data.success === 0 ? 'alert-danger' : 'alert-success' });
                setSubject(null);
                setSelectedImage(null);
                setSubjectIds([]);
                navigate('/standards');
            })
            .catch((error) => {
                console.log(error);
            });


    }
    useEffect(() => {
        subjectids();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <Box boxShadow="24" sx={{ padding: 4 }}>
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <div className="text-end">
                                <Button color={'success'} variant='contained' component={Link} href="/standards">
                                    Back
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-4">
                        <div className="col-md-12">
                            {
                                message.message ? (<>
                                    <div className={`alert ${message.alert}`}>
                                        {message.message}
                                    </div>
                                </>) : ('')
                            }
                        </div>
                        <div className="col-md-4">
                            <TextField id="standard" onChange={standardHandler} fullWidth label="Enter Standard" variant="outlined" />
                        </div>
                        <div className="col-md-4">
                            <TextField type="file" onChange={handleImageChange} id="standard" fullWidth variant="outlined" />
                        </div>
                        <div className="col-md-4">
                            <Select onChange={handleSubjectIdChange} closeMenuOnSelect={false} isMulti options={options} />
                        </div>
                        <div className="col-md-4">
                            <Button onClick={saveStandard} fullWidth color={'success'} variant='contained' component={Button}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>
            </Box>
        </>
    );
}

export default StandardCreate;
