/* eslint-disable prettier/prettier */
import React, { useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './policy.css'
import axios from '../../../node_modules/axios/index';
import { Link } from '../../../node_modules/react-router-dom/dist/index';


const CreatePolicy = () => {
    const [editorData, setEditorData] = useState('');
    const [title, setTitle] = useState(null);
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);
    const econfig = {
        toolbar: ['undo', 'redo', 'bold', 'italic', 'numberedList', 'bulletedList']
    }
    const editorStyle = {
        height: '400px', // Set the desired height here
    };
    const create_policy = async () => {
       
        let data = JSON.stringify({
            "title": title,
            "content": editorData
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/policy',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                if (response.data.success == 0) {
                    setErrors(response.data.errors)
                } else {
                    setTitle("");
                    setEditorData("")
                    setSuccess(true);
                }

            })
            .catch((error) => {
                console.log(error);
            });

    }
    return (
        <>
            <section className="py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="w-100 text-end">
                                <Link to="/policies" className="btn btn-primary rounded-1">Back</Link>
                            </div>
                        </div>

                        {
                            success === true && (
                                <>
                                    <div className="col-md-12 mb-4">
                                        <div className="alert alert-success">Create successfully</div>
                                    </div>
                                </>
                            )
                        }
                        {
                            errors.length > 0 && (
                                <>
                                    <div className="col-md-12 mb-4">
                                        <div className="alert alert-danger">
                                            <ul className="mb-0">
                                                {
                                                    errors.map((err, i) => (
                                                        <>
                                                            <li key={i + 1000}>
                                                                {err?.msg ?? err}

                                                            </li>
                                                        </>
                                                    ))
                                                }

                                            </ul>
                                        </div>
                                    </div>
                                </>
                            )
                        }

                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="title">Enter Policy Title</label>
                                <input type="text" value={title} onChange={(event) => setTitle(event.target.value)} name="title" id="title" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-12 mb-4">
                            <div className="form-group">
                                <label htmlFor="policy">policy</label>

                                <CKEditor
                                    config={econfig}
                                    editor={ClassicEditor}
                                    data={editorData}
                                    onChange={(event, editor) => {
                                        const newData = editor.getData();
                                        setEditorData(newData);
                                    }}
                                    style={editorStyle}
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <input type="submit" onClick={create_policy} value="Submit" className="btn btn-primary" />
                        </div>
                    </div>
                </div>
            </section >

        </>
    )
}

export default CreatePolicy
