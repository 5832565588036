/* eslint-disable prettier/prettier */
import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import StandardCreate from 'pages/standards/StandardCreate';
import StandardList from 'pages/standards/StandardList';
import SubjectsList from 'pages/subjects/SubjectsList';
import SubjectsCreate from 'pages/subjects/SubjectsCreate';
import TopicsList from 'pages/topics/TopicsList';
import TopicsCreate from 'pages/topics/TopicsCreate';
import ChaptersList from 'pages/chapters/ChaptersList';
import ChapterCreate from 'pages/chapters/ChapterCreate';
import NotesList from 'pages/notes/NotesList';
import NotesCreate from 'pages/notes/NotesCreate';
import VideosList from 'pages/videos/VideosList';
import VideosCreate from 'pages/videos/VideosCreate';
import UserList from 'pages/users/UserList';
import UserCreate from 'pages/users/UserCreate';
import QuestionCreate from 'pages/questions/QuestionCreate';
import QuestionList from 'pages/questions/QuestionList';
import ListPolicy from 'pages/policy/ListPolicy';
import CreatePolicy from 'pages/policy/CreatePolicy';
import ListPlan from 'pages/plans/ListPlan';
import CreatePlan from 'pages/plans/CreatePlan';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
// const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
// const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
// const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
// const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'color',
      element: <Color />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'standards',
      element: <StandardList />
    },
    {
      path: 'standards/create',
      element: <StandardCreate />
    },
    {
      path: 'subject',
      element: <SubjectsList />
    },
    {
      path: 'subject/create',
      element: <SubjectsCreate />
    },
    {
      path: 'topics',
      element: <TopicsList />
    },
    {
      path: 'topics/create',
      element: <TopicsCreate />
    },
    {
      path: 'chapters',
      element: <ChaptersList />
    },
    {
      path: 'chapters/create',
      element: <ChapterCreate />
    },
    {
      path: 'notes',
      element: <NotesList />
    },
    {
      path: 'notes/create',
      element: <NotesCreate />
    },
    {
      path: 'videos',
      element: <VideosList />
    },
    {
      path: 'videos/create',
      element: <VideosCreate />
    },
    {
      path: 'users',
      element: <UserList />
    },
    {
      path: 'users/create',
      element: <UserCreate />
    },
    {
      path: 'questions',
      element: <QuestionList />
    },
    {
      path: 'questions/create',
      element: <QuestionCreate />
    },
    {
      path: "policies",
      element : <ListPolicy/>
    },
    {
      path: "policies/create",
      element : <CreatePolicy/>
    },
    {
      path: "plans",
      element : <ListPlan/>
    },
    {
      path: "plans/create",
      element : <CreatePlan/>
    }
  ]
};

export default MainRoutes;
