/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import axios from '../../../node_modules/axios/index';
import { Alert, Button, Link } from '@mui/material/index';


function TopicsCreate() {
    const [standards, setStandards] = useState([]);
    const [chapter, setChapter] = useState([]);
    const [chapter_id, setChapter_id] = useState({});
    const [topic, setTopic] = useState(null);
    const [image, setImage] = useState(null);
    const [subjects, setSubjects] = useState([]);
    const [standard_id, setStandard_id] = useState("");
    const [subject_id, setSubject_id] = useState("");
    const [message, setMessage] = useState({ message: "", alert: "" });

    const subjectHandler = (event) => {

        setSubject_id(event.target.value);
    }
    const chapterHandler = (event) => {
        setChapter_id(event.target.value);
    }
    const handleStandard_id = async (e) => {
        let value = e.target.value;
        setSubject_id("");
        setStandard_id(value);



        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/standards/get_subject_by_standard_id/' + value,
            headers: {}
        };

        await axios.request(config)
            .then((response) => {
                setSubjects(response.data)
                setSubject_id(null);
            })
            .catch((error) => {
                console.log(error);
            });




    }
    const getStandard = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/standards/',
            headers: {}
        };

        await axios.request(config)
            .then((response) => {
                setStandards(response.data);

            })
            .catch((error) => {
                console.log(error);
            });

    }
    const handleFileChange = (event) => {
        // Handle file changes here
        const selectedFile = event.target.files[0];
        setImage(selectedFile);

    };
    const save_topic = async () => {

        const FormData = require('form-data');
        let data = new FormData();
        data.append('standard_id', standard_id);
        data.append('subject_id', subject_id);
        data.append('chapter_id', chapter_id);
        data.append('topic', topic);
        data.append('image', image);


        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/topics',
            headers: {

            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                setMessage({ message: response.data.message, alert: response.data.success === 0 ? 'alert-danger' : 'alert-success' });
                setTimeout(() => {
                    window.location.reload();
                }, 100);
            })
            .catch((error) => {
                console.log(error);
            });

    }
    const getChapter = () => {
        let sid = subject_id;
        let st_id = standard_id;
        let data = JSON.stringify({
            "standard_id": st_id,
            "subject_id": sid
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/chapters/getAll',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios.request(config)
            .then((response) => {
                if (response.status == 200) {
                    setChapter(response.data);
                    console.log(chapter);
                }

            })
            .catch((error) => {
                console.log(error);
            });

    }

    useEffect(() => {
        getStandard();

    }, [])
    useEffect(() => {
        getChapter();
    }, [standard_id, subject_id])




    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="w-100 card card-body">
                                <div className="row gy-4">
                                    <div className="col-md-12 mb-3">
                                        {message.message ? (
                                            <>
                                                <Alert severity="success">{message.message}</Alert>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div className="col-md-12">
                                        <div className="text-end">
                                            <Button component={Link} href="/topics" variant="contained" color="success">Back</Button>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='w-100'>
                                            <TextField fullWidth defaultValue={standard_id} value={standard_id} select onChange={handleStandard_id} label="Select Standard"  >
                                                {standards.map((option, i) => (
                                                    <MenuItem key={`stan_d${option._id}${i} `} value={option._id}>
                                                        {option.standard}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='w-100'>
                                            <TextField select defaultValue={subject_id} value={subject_id} onChange={subjectHandler} fullWidth label="Select Subject"  >
                                                <MenuItem key='0' value="">---Select--</MenuItem>
                                                {subjects.map((option) => (
                                                    <MenuItem key={`subjects${option._id}`} value={option._id}>
                                                        {option.subject}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="w-100">
                                            <TextField fullWidth defaultValue="" value={chapter_id} select label="Select Chapter " onChange={chapterHandler} >
                                                <MenuItem key='0' value="">---Select--</MenuItem>
                                                {chapter.map((option) => (
                                                    <MenuItem key={`chapter${option._id}`} value={option._id}>
                                                        {option.chapter}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="w-100">
                                            {/* <InputLabel htmlFor="file-input">Upload Chapter Image</InputLabel> */}
                                            <TextField
                                                id="file-input"
                                                type="file"
                                                fullWidth

                                                onChange={handleFileChange}
                                                inputProps={{ accept: 'image/*' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="w-100">

                                            <TextField value={topic}
                                                id="topic"
                                                type="text"
                                                fullWidth

                                                onChange={(event) => setTopic(event.target.value)}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="col-md-4">
                                            <Button variant="contained" onClick={save_topic} color="success" fullWidth>Submit</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TopicsCreate