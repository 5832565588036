/* eslint-disable prettier/prettier */
// material-ui
// import { useTheme } from '@mui/material/styles';
import Image from "../../../node_modules/react-bootstrap/esm/Image";
import logo from 'assets/images/logo.png';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  // const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Mantis" width="100" />
     *
     */
    <>
      <Image width={150} className="img-fluid d-block mx-auto" src={logo} />
    </>
  );
};

export default Logo;
