/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import axios from '../../../node_modules/axios/index';
import { Alert, Button, Link } from '../../../node_modules/@mui/material/index';
import moment from '../../../node_modules/moment/moment';
import Image from '../../../node_modules/react-bootstrap/esm/Image';
import { Modal } from '../../../node_modules/react-bootstrap/esm/index';

function SubjectsList() {
    const [data, setData] = useState([]);
    const [message, setMessage] = useState({ message: '', alert: '' });
    const [id, setId] = useState(null);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const getAll = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/subjects/',
            headers: {}
        };

        await axios
            .request(config)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const deleteSubject = (id) => {
        setId(id);
        handleOpen();
    };
    const deleteSubjectNow = () => {
        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: 'http://194.163.34.149:8185/subjects/delete/' + id,
            headers: {}
        };

        axios
            .request(config)
            .then((response) => {
                setMessage({ message: response.data.message, alert: response.data.success === 1 ? 'alert-success' : 'alert-danger' });
                getAll();
                handleClose();
                setId(null);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        getAll();
    }, []);

    return (
        <>
            <section className="py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="w-100 text-end">
                                <Button color="success" variant="contained" component={Link} href="/subject/create">
                                    Add New Subject
                                </Button>
                            </div>
                        </div>
                        <div className="col-md-12 mb-3">
                            {message.message ? (
                                <>
                                    <Alert severity="success">{message.message}</Alert>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-sm table-bordered">
                                    <thead className="bg-warning">
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Subject</th>
                                            <th>Image</th>
                                            <th>Created At</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, i) => (
                                            <>
                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td>{item.subject}</td>
                                                    <td>
                                                        <Image width={50} src={`http://194.163.34.149:8185/uploads/${item.image.name}`} />
                                                    </td>
                                                    <td>{moment(item.createdAt).format('D-M-Y H:m A')}</td>
                                                    <td>
                                                        <div className="d-flex gap-1">
                                                            <Button color="warning" variant="contained" component={Link} href="/subjects/edit/">
                                                                Edit
                                                            </Button>
                                                            <Button color="error" variant="contained" onClick={() => deleteSubject(item._id)}>
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal show={open} onHide={handleClose}>
                <Modal.Body>Are you sure ?</Modal.Body>
                <Modal.Footer>
                    <Button color="success" variant="contained" onClick={handleClose}>
                        No
                    </Button>
                    <Button className="ms-3" color="error" variant="contained" onClick={deleteSubjectNow}>
                        Yes , Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SubjectsList;
